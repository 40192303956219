var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { api } from "./http.service";
import { env } from "../constants/global.constants";
import { authSubject, auth } from "../store/auth.store";
import { Storage } from "./localstorege.service";
import { Member } from "../models/auth.model";
import { wssProvider } from "./websocket.service";
import { modalServiceSubject } from "./modal.service";
import { exceptionModal, getSHA256Hash, gtmUserEvent } from "./misc.functions";
import paths from "../paths";
import { loginSubject } from "../store/login.store";
export function restoreLogin() {
    return __awaiter(this, void 0, void 0, function () {
        var savedData, sha256Mail, sha256Mobile, sha256Id, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    savedData = Storage.get("member");
                    if (!savedData) return [3 /*break*/, 7];
                    auth.member = new Member(savedData);
                    auth.token = Storage.get("token");
                    auth.authentication = true;
                    auth.mobileValidation = auth.member.mobile_validation;
                    loginSubject.next(new Member(savedData));
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    // @ts-ignore
                    window.userInfo = window.userInfo || [];
                    return [4 /*yield*/, getSHA256Hash(auth.member.email)];
                case 2:
                    sha256Mail = _a.sent();
                    return [4 /*yield*/, getSHA256Hash(auth.member.mobile)];
                case 3:
                    sha256Mobile = _a.sent();
                    return [4 /*yield*/, getSHA256Hash(auth.member.id)];
                case 4:
                    sha256Id = _a.sent();
                    // @ts-ignore
                    userInfo.push({
                        cd_email: sha256Mail,
                        cd_phone: sha256Mobile,
                        cd_userid: sha256Id,
                        cd_loginstatus: true,
                        cd_TLbakiye: auth.member.balance_debit,
                        cd_SPbakiye: auth.member.balance_point, //Kullanıcının ŞP cinsinden bakiye değeri.
                    });
                    return [3 /*break*/, 6];
                case 5:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 6];
                case 6:
                    getGameRemains();
                    _a.label = 7;
                case 7: return [2 /*return*/];
            }
        });
    });
}
export function getGameRemains() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(auth.member && auth.token)) return [3 /*break*/, 2];
                    return [4 /*yield*/, api
                            .start("", env.accounting_api + "/misc/api/list-game-reminder/", {}, true)
                            .then(function (res) {
                            if (res && res.status) {
                                auth.remaining_games = res.data.map(function (el, key) {
                                    return el.game_id;
                                });
                                // console.log(auth.remaining_games)
                                authSubject.next({ action: "checkAuth" });
                            }
                        })
                            .catch(function (e) { })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    });
}
export function userLogin(payload) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api
                        .start("post", env.accounting_api + "/member/api/login/", payload, false)
                        .then(function (res) {
                        if (res && res.status) {
                            auth.member = new Member(res.data.member);
                            auth.token = res.data.token;
                            auth.authentication = true;
                            auth.mobileValidation = res.data.member.mobile_validation;
                            Storage.set("token", res.data.token);
                            Storage.set("member", res.data.member);
                            getGameRemains();
                            authSubject.next({ action: "login" });
                            loginSubject.next(new Member(res.data.member));
                            wssProvider.user(auth.token);
                        }
                        return res;
                    })
                        .catch(function (e) {
                        return { status: false, error: e, message: e, data: null };
                    })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function userLogout() {
    gtmUserEvent("logout");
    auth.member = null;
    auth.token = null;
    auth.authentication = false;
    auth.mobileValidation = false;
    auth.remaining_games = [];
    Storage.del("token");
    Storage.del("member");
    authSubject.next({ action: "logout" });
}
export function sendValidationCode(payload) {
    api
        .start("post", env.accounting_api + "/member/api/validation-member-sms/", payload, false)
        .then(function (res) {
        // console.log(res)
    })
        .catch(function (e) {
        exceptionModal(e);
    });
}
export function sendValidationUpdateCode(payload) {
    api
        .start("post", env.accounting_api + "/member/api/update-account-info/", payload, true)
        .then(function (res) {
        // console.log(res)
    })
        .catch(function (e) {
        exceptionModal(e);
    });
}
export function userValidation(payload, resend) {
    if (resend === void 0) { resend = false; }
    return __awaiter(this, void 0, void 0, function () {
        var mobile, s, sureButton, resendButton, validationErrorMessage, senderTimer;
        var _this = this;
        return __generator(this, function (_a) {
            if (resend) {
                sendValidationCode(payload);
            }
            mobile = payload.mobile;
            s = null;
            sureButton = null;
            resendButton = null;
            validationErrorMessage = null;
            senderTimer = function (s) {
                if (s === void 0) { s = null; }
                var resendTimer = document.getElementById("id_resendTimer");
                if (resendTimer) {
                    var secondLeft = Number(Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1);
                    if (secondLeft < 0) {
                        clearInterval(s);
                        if (resendButton) {
                            resendButton.style.visibility = "visible";
                        }
                    }
                    else {
                        resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
                    }
                }
            };
            modalServiceSubject.next({
                disableBackdrop: true,
                title: "Cep Numaranızı Doğrulayın",
                closeIcon: true,
                confirm: {
                    sure: {
                        class: "btn btn-green",
                        label: "Doğrula",
                        autoClose: false,
                        async: true,
                        action: function () { return __awaiter(_this, void 0, void 0, function () {
                            var inputElements, validateCodeValue;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        validationErrorMessage.style.display = "none";
                                        inputElements = document.querySelectorAll("input.code-input");
                                        validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; }).join("");
                                        return [4 /*yield*/, api
                                                .start("post", env.accounting_api + "/member/api/validation-sms/", {
                                                mobile: mobile,
                                                type: "register_validate",
                                                code: validateCodeValue,
                                            }, false)
                                                .then(function (res) {
                                                if (res && res.status) {
                                                    auth.userValidation = true;
                                                    return true;
                                                }
                                                else {
                                                    if (validationErrorMessage) {
                                                        validationErrorMessage.style.display = "block";
                                                        validationErrorMessage.innerHTML = res.message;
                                                        __spreadArray([], inputElements, true).map(function (inp) { return (inp.value = ""); });
                                                    }
                                                }
                                            })
                                                .catch(function (e) {
                                                if (validationErrorMessage) {
                                                    validationErrorMessage.style.display = "block";
                                                    validationErrorMessage.innerHTML = e;
                                                }
                                            })];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); },
                    },
                },
                onClose: function () {
                    clearInterval(s);
                },
                afterLoad: function () {
                    s = setInterval(senderTimer, 1000);
                    validationErrorMessage = document.getElementById("id_validationErrorMessage");
                    if (validationErrorMessage) {
                        validationErrorMessage.style.display = "none";
                    }
                    sureButton = document.getElementById("id_btnModelServiceSure");
                    resendButton = document.getElementById("id_resendCode");
                    if (sureButton) {
                        sureButton.disabled = true;
                    }
                    if (resendButton) {
                        resendButton.style.visibility = "hidden";
                        resendButton.addEventListener("click", function (e) {
                            resendButton.style.visibility = "hidden";
                            var resendTimer = document.getElementById("id_resendTimer");
                            if (resendTimer) {
                                if (validationErrorMessage) {
                                    validationErrorMessage.style.display = "none";
                                }
                                sendValidationCode(payload);
                                resendTimer.innerHTML = "3:00";
                                clearInterval(s);
                                s = setInterval(senderTimer, 1000);
                            }
                        });
                    }
                    var inputElements = document.querySelectorAll("input.code-input");
                    inputElements.forEach(function (ele, index) {
                        ele.addEventListener("keydown", function (e) {
                            // if the keycode is backspace & the current field is empty
                            // focus the input before the current. Then the event happens
                            // which will clear the "before" input box.
                            if (e.keyCode === 8 && e.target.value === "")
                                inputElements[Math.max(0, index - 1)].focus();
                        });
                        ele.addEventListener("input", function (e) {
                            // take the first character of the input
                            // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
                            // but I'm willing to overlook insane security code practices.
                            var _a = e.target.value, first = _a[0], rest = _a.slice(1);
                            e.target.value = first !== null && first !== void 0 ? first : ""; // first will be undefined when backspace was entered, so set the input to ""
                            var lastInputBox = index === inputElements.length - 1;
                            var didInsertContent = first !== undefined;
                            if (didInsertContent && !lastInputBox) {
                                // continue to input the rest of the string
                                inputElements[index + 1].focus();
                                // inputElements[index+1].value = rest.join('')
                                inputElements[index + 1].dispatchEvent(new Event("input"));
                            }
                            var validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; }).join("");
                            if (validateCodeValue.length === 6) {
                                sureButton.disabled = false;
                            }
                            else {
                                sureButton.disabled = true;
                            }
                        });
                    });
                },
                content: "\n      <p>\n        ".concat(mobile, " GSM numaral\u0131 telefonunuza g\u00F6nderdi\u011Fimiz do\u011Frulama kodunu giriniz.\n      </p>\n      <div>\n      \n        <div id=\"id_validationErrorMessage\" class=\"text-center alert alert-danger\"></div>\n          \n        <div class=\"mb-3 px-3 d-flex\">\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <div class=\"clearfix\"></div>\n        </div>\n        <div id=\"emailHelp\" class=\"form-text\"><span id=\"id_resendTimer\">3:00</span> sonra tekrar g\u00F6nder</div>\n        <button style=\"bottom: 8px;\" class=\"btn btn-warning\" id=\"id_resendCode\">Tekrar G\u00F6nder</button>\n      </div>"),
            });
            return [2 /*return*/];
        });
    });
}
export function sendEmailValidationLink() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            api
                .start("post", env.accounting_api + "/member/api/validation-email/", {
                type: "register_send",
                email: auth.member.email,
                return_address: "".concat(window.location.protocol, "//").concat(window.location.host).concat(paths.verifyEmail.url),
            }, true)
                .then(function (res) {
                // console.log(res)
                if (res && res.status) {
                    modalServiceSubject.next({
                        case: "success",
                        title: res.message,
                        content: "<p>L\u00FCtfen eposta adresinize g\u00F6nderilen do\u011Frulama linkini t\u0131klayarak do\u011Frulama i\u015Fleminizi tamamlay\u0131n. \n                    E\u011Fer bir eposta almazsan\u0131z l\u00FCtfen m\u00FC\u015Fteri hizmetlerinden destek talep ediniz. </p>",
                        confirm: {
                            cancel: {
                                label: "Tamam",
                            },
                        },
                    });
                }
            });
            return [2 /*return*/];
        });
    });
}
export function userValidationUpdate(payload, resend) {
    if (resend === void 0) { resend = false; }
    return __awaiter(this, void 0, void 0, function () {
        var mobile, s, sureButton, resendButton, validationErrorMessage, senderTimer;
        var _this = this;
        return __generator(this, function (_a) {
            if (resend) {
                sendValidationUpdateCode(payload);
            }
            mobile = payload.mobile;
            s = null;
            sureButton = null;
            resendButton = null;
            validationErrorMessage = null;
            senderTimer = function (s) {
                if (s === void 0) { s = null; }
                var resendTimer = document.getElementById("id_resendTimer");
                if (resendTimer) {
                    var secondLeft = Number(Number(resendTimer.innerHTML.split(":")[0]) * 60 + Number(resendTimer.innerHTML.split(":")[1]) - 1);
                    if (secondLeft < 0) {
                        clearInterval(s);
                        if (resendButton) {
                            resendButton.style.visibility = "visible";
                        }
                    }
                    else {
                        resendTimer.innerHTML = Math.floor(secondLeft / 60) + ":" + ("00" + (secondLeft % 60)).slice(-2);
                    }
                }
            };
            modalServiceSubject.next({
                disableBackdrop: true,
                title: "Cep Numaranızı Doğrulayın",
                closeIcon: true,
                confirm: {
                    sure: {
                        class: "btn btn-green",
                        label: "Doğrula",
                        autoClose: false,
                        async: true,
                        action: function () { return __awaiter(_this, void 0, void 0, function () {
                            var inputElements, validateCodeValue;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        validationErrorMessage.style.display = "none";
                                        inputElements = document.querySelectorAll("input.code-input");
                                        validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; }).join("");
                                        return [4 /*yield*/, api
                                                .start("post", env.accounting_api + "/member/api/update-account-info/", {
                                                mobile: mobile,
                                                sms_verify_code: validateCodeValue,
                                            }, true)
                                                .then(function (res) {
                                                if (res && res.status) {
                                                    auth.mobileValidation = true;
                                                    return true;
                                                }
                                                else {
                                                    if (validationErrorMessage) {
                                                        validationErrorMessage.style.display = "block";
                                                        validationErrorMessage.innerHTML = res.message;
                                                        __spreadArray([], inputElements, true).map(function (inp) { return (inp.value = ""); });
                                                    }
                                                }
                                            })
                                                .catch(function (e) {
                                                if (validationErrorMessage) {
                                                    validationErrorMessage.style.display = "block";
                                                    validationErrorMessage.innerHTML = e;
                                                }
                                            })];
                                    case 1: return [2 /*return*/, _a.sent()];
                                }
                            });
                        }); },
                    },
                },
                onClose: function () {
                    clearInterval(s);
                },
                afterLoad: function () {
                    s = setInterval(senderTimer, 1000);
                    validationErrorMessage = document.getElementById("id_validationErrorMessage");
                    if (validationErrorMessage) {
                        validationErrorMessage.style.display = "none";
                    }
                    sureButton = document.getElementById("id_btnModelServiceSure");
                    resendButton = document.getElementById("id_resendCode");
                    if (sureButton) {
                        sureButton.disabled = true;
                    }
                    if (resendButton) {
                        resendButton.style.visibility = "hidden";
                        resendButton.addEventListener("click", function (e) {
                            resendButton.style.visibility = "hidden";
                            var resendTimer = document.getElementById("id_resendTimer");
                            if (resendTimer) {
                                if (validationErrorMessage) {
                                    validationErrorMessage.style.display = "none";
                                }
                                sendValidationCode(payload);
                                resendTimer.innerHTML = "3:00";
                                clearInterval(s);
                                s = setInterval(senderTimer, 1000);
                            }
                        });
                    }
                    var inputElements = document.querySelectorAll("input.code-input");
                    inputElements.forEach(function (ele, index) {
                        ele.addEventListener("keydown", function (e) {
                            // if the keycode is backspace & the current field is empty
                            // focus the input before the current. Then the event happens
                            // which will clear the "before" input box.
                            if (e.keyCode === 8 && e.target.value === "")
                                inputElements[Math.max(0, index - 1)].focus();
                        });
                        ele.addEventListener("input", function (e) {
                            // take the first character of the input
                            // this actually breaks if you input an emoji like 👨‍👩‍👧‍👦....
                            // but I'm willing to overlook insane security code practices.
                            var _a = e.target.value, first = _a[0], rest = _a.slice(1);
                            e.target.value = first !== null && first !== void 0 ? first : ""; // first will be undefined when backspace was entered, so set the input to ""
                            var lastInputBox = index === inputElements.length - 1;
                            var didInsertContent = first !== undefined;
                            if (didInsertContent && !lastInputBox) {
                                // continue to input the rest of the string
                                inputElements[index + 1].focus();
                                // inputElements[index+1].value = rest.join('')
                                inputElements[index + 1].dispatchEvent(new Event("input"));
                            }
                            var validateCodeValue = __spreadArray([], inputElements, true).map(function (inp) { return inp.value; }).join("");
                            if (validateCodeValue.length === 6) {
                                sureButton.disabled = false;
                            }
                            else {
                                sureButton.disabled = true;
                            }
                        });
                    });
                },
                content: "\n      <p>\n        ".concat(mobile, " GSM numaral\u0131 telefonunuza g\u00F6nderdi\u011Fimiz do\u011Frulama kodunu giriniz.\n      </p>\n      <div>\n      \n        <div id=\"id_validationErrorMessage\" class=\"text-center alert alert-danger\"></div>\n          \n        <div class=\"mb-3 px-3 d-flex\">\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <input type=\"text\" autocomplete=\"off\" name='char' maxlength=\"1\" class='code-input col-auto' required/>\n          <div class=\"clearfix\"></div>\n        </div>\n        <div id=\"emailHelp\" class=\"form-text\"><span id=\"id_resendTimer\">3:00</span> sonra tekrar g\u00F6nder</div>\n        <button style=\"bottom: 8px;\" class=\"btn btn-warning\" id=\"id_resendCode\">Tekrar G\u00F6nder</button>\n      </div>"),
            });
            return [2 /*return*/];
        });
    });
}
